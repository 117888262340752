.resume_container,
.link_section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

hr {
  width: 100%;
}

.resumeButtonContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  right: 25px;
  top: 100px;
}

.resumebutton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #0e8388;
  color: #000;
  font-size: 20px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
  width: 45px;
  height: 45px;
}

.resumeButton:hover {
  background-color: #0056b3;
}

.ma_0 {
  margin: 0;
}

.resume_container {
  align-items: flex-start;
  margin: 10px 0;
}

/* Title */
.title {
  margin-bottom: 0;
  text-decoration: uppercase;
  padding-left: 10px;
}

.title_container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

/* Link Section */
.link_section {
  align-items: flex-start;
}

.link_section_row {
  align-items: center;
  display: flex;
}

.link_label {
  margin: 0 5px 0 10px;
}

/* Professional Experience Section */
.pro_exp_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.job_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  width: 95%;
}

.company_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.company_name {
  margin: 0;
}

.job_title_container {
  margin: 0 10px;
}

.job_title {
  margin: 0;
}

.job_title_tools {
  font-size: 10px;
  margin: 0;
}

/* Projects Section */
.projects_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.project_name_container {
  display: flex;
  flex-direction: column;
}

.project_name {
  margin: 0 10px;
}

.project_ul {
  margin: 0 10px 0 0;
}

.project_link {
  margin: 0 10px;
}

.project_tools {
  font-size: 10px;
  margin: 10px 10px;
  line-height: 10px;
}

/* Education */
.education_container {
  display: flex;
  flex-direction: column;
}

.university {
  margin: 10px 0 0 10px;
}

.u_par {
  margin: 0 0 0 10px;
}

.related_work {
  margin: 10px 0 0 10px;
}
/* Medium Devices: IPads / Tablets */
@media screen and (min-width: 481px) {
  .resume_container {
    margin: 20px auto;
    width: 90%;
  }

  .title_container {
    align-items: flex-start;
  }

  .company_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;
    width: 100%;
  }

  .job_title_container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
    margin-bottom: 0;
    width: 100%;
  }

  .project_name_container {
    flex-direction: row;
    justify-self: flex-start;
    align-items: center;
  }

  .project_name {
    margin: 0 5px 0 10px;
  }

  .project_link {
    margin: 0;
  }

  .job_title_tools,
  .project_tools {
    font-size: 12px;
  }

  .education_container {
    margin-bottom: 15px;
  }
}

/* Large Devices: Small Screens Laptops*/
@media screen and (min-width: 769px) {
  .link_section {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .link_section_row {
    flex-basis: 50%;
  }

  .resumeButtonContainer {
    right: 50px;
  }
}

/* XLarge Devices: Small Screens Laptops*/
@media screen and (min-width: 1025px) {
  .resume_container {
    width: 50%;
  }
}
