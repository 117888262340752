.card_container {
  align-items: center;
  border-radius: 6%;
  /* border: solid #000 1px; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  height: 350px;
  transition: 0.3s;
  width: 100%;
}

.card_container:hover {
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.2);
}

.card_discription {
  font-family: "Kalam", cursive;
  margin: 0 20px;
}

/* Medium Devices: IPads / Tablets */
@media screen and (min-width: 481px) {
  .card_container {
    width: 325px;
  }
}

/* Large Devices: Small Screens Laptops*/
@media screen and (min-width: 769px) {
  .card_container {
    width: 340px;
  }

  .about_me_discription {
    width: 60%;
  }
}

/* XLarge Devices: Small Screens Laptops*/
@media screen and (min-width: 1025px) {
  .card_container {
    width: 375px;
  }
}
