.button_container {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0 0;
  width: 100%;
}
.button_container button {
  margin-right: 10px;
}

.card_container {
  align-items: center;
  border-radius: 6%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
  transition: 0.3s;
  width: 100%;
}

.card_container:hover {
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.2);
}

.card_discription {
  margin: 0 20px;
}

.card_title {
  margin-bottom: 10px;
}

hr {
  border: solid 1px #4a4a4a;
  display: block;
  margin-bottom: 15px;
  overflow: hidden;
  width: 90%;
}

/* Medium Devices: IPads / Tablets */
@media screen and (min-width: 481px) {
  .card_container {
    height: 280px;
    width: 325px;
  }
}

/* Large Devices: Small Screens Laptops*/
@media screen and (min-width: 769px) {
  .card_container {
    width: 340px;
  }
}

/* XLarge Devices: Small Screens Laptops*/
@media screen and (min-width: 1025px) {
}
