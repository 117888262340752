.link {
  color: #0e8388;
  font-size: 18px;
  margin: 0 10px;
  text-decoration: none;
  text-transform: uppercase;
}

.link_container {
  padding-right: 0px;
}

.link_container,
.login_container {
  display: none;
}

.mobile_dropdown {
  background-color: #333;
  overflow: hidden;
  padding-top: 10px;
  position: absolute;
  top: 80px;
  width: 100%;
  z-index: 100;
}

.mobile_dropdown .link {
  color: #0e8388;
  margin: 0 15px 10px;
}

.mobile_dropdown_hidden {
  display: none;
}

.mobile_dropdown_showing {
  display: flex;
  flex-direction: column;
}

.name {
  color: #0e8388;
  font-size: 25px;
  white-space: none;
  text-transform: uppercase;
}

.name_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  width: 100%;
}

.navbar {
  align-items: center;
  background-color: #000;
  display: flex;
  height: 80px;
  justify-content: center;
  margin-top: 0px;
}

.menu_button_container {
  margin-right: 15px;
}

@media screen and (min-width: 700px) {
  .navbar {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1024px) {
  .navbar {
    flex-direction: row;
    justify-content: space-between;
  }

  .name_container {
    padding-left: 35px;
  }

  .link_container {
    padding-right: 25px;
  }

  .link_container {
    display: flex;
    flex-basis: 55%;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .login_container {
    display: flex;
  }

  .mobile_dropdown,
  .menu_button_container {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .link_container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
}
