.button {
  background-color: #0e8388;
  border-radius: 14px;
  box-shadow: 0px 8px 24px lightgray;
  color: black;
  cursor: pointer;
  font-size: 16px;
  font-family: moderno-fb, Georgia, "Times New Roman", Times, serif;
  font-weight: 400px;
  letter-spacing: 0.05em;
  line-height: 32px;
  margin: 10px 0px;
  outline: 0;
  padding: 5px 15px;
  text-transform: uppercase;
  transition: ease background-color 250ms;
}

.button:hover {
  background-color: #0e8388;
}

.button:disabled {
  cursor: default;
  opacity: 0.7;
}
