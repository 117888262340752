.page_container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.project_discription {
  font-size: 20px;
  margin-bottom: 25px;
  width: 90%;
}

.project_title {
  margin-bottom: 25px;
  padding-top: 25px;
}

@media screen and (min-width: 700px) {
  .project_discription {
    width: 60%;
  }
}
