.footer_container {
  display: flex;
  background-color: #000;
  bottom: 0;
  justify-content: center;
  position: absolute;
  height: 80px;
  width: 100%;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.link_text {
  color: #0e8388;
  font-size: 18px;
  margin: 0 10px;
  text-decoration: none;
  text-transform: capitalize;
}

@media screen and (min-width: 700px) {
  .footer_container {
    gap: 40px;
  }

  .link_text {
    color: #0e8388;
    font-size: 25px;
  }
}
