body {
  margin: 0;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

.serif {
  font-family: moderno-fb, Georgia, "Times New Roman", Times, serif;
  font-weight: 400px;
  letter-spacing: 0.05em;
  line-height: 32px;
}

.san-serif {
  font-family: europa, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400px;
  letter-spacing: 0.01em;
  line-height: 32px;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#page-content {
  padding-bottom: 100px;
}
