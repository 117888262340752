.about_me_container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 50px 0 25px;
}

.about_me_discription {
  font-size: 20px;
  margin-bottom: 25px;
  width: 90%;
}

.about_me_title {
  margin-bottom: 25px;
}

.profile_image {
  border-radius: 50%;
  margin-bottom: 25px;
  width: 250px;
}

.testimonial_container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 10px 25px;
  width: 90%;
}

.testimonials_title {
  margin-bottom: 40px;
}

/* Medium Devices: IPads / Tablets */
@media screen and (min-width: 481px) {
  .testimonial_container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 0;
  }
}

/* Large Devices: Small Screens Laptops*/
@media screen and (min-width: 769px) {
  .about_me_discription {
    width: 70%;
  }

  .testimonial_container {
    width: 70%;
  }
}

/* XLarge Devices: Small Screens Laptops*/
@media screen and (min-width: 1025px) {
}
